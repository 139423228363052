import React, { Component } from "react";
import { Link } from "react-router-dom";
import { user_info } from "../../config/config";

export default class Sidebar extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="bg-success  border-right text-white" id="sidebar-wrapper">
                    <div className="sidebar-heading"> m-Thesis Portal </div>
                    {
                        // Students
                        (user_info.user_role_id == "3" ?  
                         <div className="list-group list-group-flush bg-success text-left">
                            <button className="dropdown-btn"><i className="fa fa-scroll" aria-hidden="true"></i> Thesis Management<i className="fa fa-caret-down"></i></button>
                            <div className="dropdown-container">
                                <div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/profile`}><i className="fa fa-user" aria-hidden="true"></i> Your Profile</Link>
                                    </div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/biography`}><i className="fa fa-user" aria-hidden="true"></i> Your Biography</Link>
                                    </div>
                                    <div className="border">
                                        <button className="dropdown-btn"><i className="fa fa-copy" aria-hidden="true"></i> Electronic Form<i className="fa fa-caret-down"></i></button>
                                        <div className="dropdown-container">
                                            <div>
                                                <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/committee`}><i className="fa fa-users" aria-hidden="true"></i> Committee & Examiner</Link>
                                            </div>
                                            <div>
                                                <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/topic`}><i className="fa fa-star" aria-hidden="true"></i> Topic</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/thesis`}><i className="fa fa-spell-check" aria-hidden="true"></i> Submission & Approval</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                    )}


                    
                    {
                        // Committee & Examiners
                        (user_info.user_role_id == "2" ?  
                         <div className="list-group list-group-flush bg-success text-left">
                            <button className="dropdown-btn"><i className="fa fa-scroll" aria-hidden="true"></i> Thesis Management<i className="fa fa-caret-down"></i></button>
                            <div className="dropdown-container">
                                <div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/review`}><i className="fa fa-user" aria-hidden="true"></i> Review Thesis</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                    )}
                   
                    <div className="list-group list-group-flush bg-success text-left">
                        {/* <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/dashboard`}><i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard</Link> */}
                        <button className="dropdown-btn"><i className="fa fa-book" aria-hidden="true"></i> Book Management<i className="fa fa-caret-down"></i></button>
                        <div className="dropdown-container">
                            {
                                 (user_info.user_role_id == "1" ?
                                 <div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/category`}><i className="fa fa-snowflake" aria-hidden="true"></i> Book Category</Link>
                                    </div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/author`}><i className="fa fa-users" aria-hidden="true"></i> Author</Link>
                                    </div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/publisher`}><i className="fa fa-building" aria-hidden="true"></i> Publisher</Link>
                                    </div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/booktype`}><i className="fa fa-list" aria-hidden="true"></i> Book Type</Link>
                                    </div>
                                    <div>
                                        <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/department`}><i className="fa fa-building" aria-hidden="true"></i> Department</Link>
                                    </div>
                                 </div>
                                 
                             :"")
                            }
                            
                            <div>
                                <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/book`}><i className="fa fa-book" aria-hidden="true"></i> Books</Link>
                            </div>
                        </div>
                        
                            {
                                 (user_info.user_role_id == "1" ?
                                 <div>
                                    <button className="dropdown-btn"><i className="fa fa-handshake" aria-hidden="true"></i> Book Circulation<i className="fa fa-caret-down"></i></button>
                                    <div className="dropdown-container">
                                            <div>
                                                <div>
                                                    <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/borrow`}><i className="fa fa-layer-group" aria-hidden="true"></i> Borrow Request</Link>
                                                </div>
                                                <div>
                                                    <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/rental`}><i className="fa fa-layer-group" aria-hidden="true"></i> Book Rental</Link>
                                                </div>
                                            </div>
                                    </div>
                                 </div>
                                 
                             :
                                <div>
                                    <button className="dropdown-btn"><i className="fa fa-handshake" aria-hidden="true"></i> Borrow Request<i className="fa fa-caret-down"></i></button>
                                    <div className="dropdown-container">
                                            <div>
                                                <div>
                                                    <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/borrow`}><i className="fa fa-layer-group" aria-hidden="true"></i> Borrow Request</Link>
                                                </div>
                                            </div>
                                    </div>
                                 </div>)
                            }
                       
                       
                            {

                                (user_info.user_role_id == "1" ?
                                    <div>
                                        <button className="dropdown-btn"><i className="fa fa-cog" aria-hidden="true"></i> System Configuration <i className="fa fa-caret-down"></i></button>
                                        <div className="dropdown-container">
                                            <div>
                                                <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/userrole`}><i className="fa fa-key" aria-hidden="true"></i> User Role</Link>
                                                <Link className="bg-success list-group-item list-group-item-sidebar list-group-item-action text-white" to={`/user`}><i className="fa fa-users" aria-hidden="true"></i> User</Link>
                                            </div>
                                        </div>
                                    </div> 
                                :
                                "")
                            }
                        {/* <a href="#" className="bg-dark list-group-item list-group-item-sidebar list-group-item-action text-white">Shortcuts</a>
                        <a href="#" className="bg-dark list-group-item list-group-item-sidebar list-group-item-action text-white">Overview</a> */}
                    </div>
                </div>
            </div>
        );
    }
}