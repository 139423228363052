import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./components/Login/login";
import Dashboard from "./components/Dashboard/Dashboard";
import { useHistory } from "react-router-dom";
import IdleTimerComponent from "./components/IdleTimer/IdleTimer";
import Sidebar from './components/Sidebar/sidebar';
import Navbar from './components/Navbar/navbar';
import ChangePassword from './components/ChangePassword/changepassword';
import Userrole from './components/UserRole/userrole';
import User from './components/User/user';
import Category from './components/Category/category';
import Author from './components/Author/author';
import Publisher from './components/Publisher/publisher';
import Booktype from './components/Booktype/booktype';
import Department from './components/Department/department';
import Book from './components/Book/book';
import Borrow from './components/Borrow/borrow';
import Rental from './components/Rental/rental';
import Profile from './components/Profile/profile';
import Biography from './components/Biography/biography';
import Committee from './components/Committee/committee';
import Topic from './components/Topic/topic';
import Thesis from './components/Thesis/thesis';
import Review from './components/Review/review';

function App() {
  let history = useHistory();
  if(!localStorage.getItem('user_info')) {
    history.push("/login");
  }

  return (
    // <Router basename={'/admin'}>
    <Router>
      <div className="App">
        <Switch>
            {
              (!localStorage.getItem('user_info') ?
                <React.Fragment>
                  <Route exact path='/login' component={Login} />
                </React.Fragment>
              : 
                <React.Fragment>
                  <IdleTimerComponent></IdleTimerComponent>
                  <div className="d-flex" id="wrapper">

                    <Sidebar></Sidebar>

                    {/* <!-- Page Content --> */}

                    <div id="page-content-wrapper">

                      <Navbar></Navbar>

                      <div className="container-fluid">
                        <Route
                          path='/profile'
                          render={(props) => (
                            <Profile {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/biography'
                          render={(props) => (
                            <Biography {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/committee'
                          render={(props) => (
                            <Committee {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/topic'
                          render={(props) => (
                            <Topic {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/thesis'
                          render={(props) => (
                            <Thesis {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/review'
                          render={(props) => (
                            <Review {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route exact path='/login' component={Login} />
                        <Route
                          path='/dashboard'
                          render={(props) => (
                            <Dashboard {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/changepassword'
                          render={(props) => (
                            <ChangePassword {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/userrole'
                          render={(props) => (
                            <Userrole {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/user'
                          render={(props) => (
                            <User {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/category'
                          render={(props) => (
                            <Category {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/author'
                          render={(props) => (
                            <Author {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/publisher'
                          render={(props) => (
                            <Publisher {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                        <Route
                          path='/booktype'
                          render={(props) => (
                            <Booktype {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                         <Route
                          path='/department'
                          render={(props) => (
                            <Department {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                         <Route
                          path='/book'
                          render={(props) => (
                            <Book {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                         <Route
                          path='/borrow'
                          render={(props) => (
                            <Borrow {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                         <Route
                          path='/rental'
                          render={(props) => (
                            <Rental {...props} user_info={JSON.parse(localStorage.getItem('user_info'))} />
                          )}
                        />
                      </div>
                    </div>


                    {/* <!-- /#page-content-wrapper --> */}

                  </div>
                  {/* <!-- /#wrapper --> */}
                </React.Fragment>
              )
            }
        </Switch>
      </div>
    </Router>
  );
}

export default App;
